import { Spinner } from "../@components/Spinner"

class Layout {
  $document: any
  $target: any
  pageType: any
  constructor(options: any = {
    pageType: "team-reports",
    selector: ".content-box",
  }) {
    this.$document = $(document);
    this.$target = this.$document.find(options.selector);
    this.pageType = options.pageType;

    moment.locale(Translator.locale)
  }

  toggleLoader(element: any, toggle: any) {
    const $element = $(element);

    $element.toggleClass("is-loading", toggle);
    if (toggle) {
      Spinner($element).append();
    } else {
      Spinner($element).remove();
    }
  }

  toggleDisabled(element: any, toggle: any) {
    const $element = $(element);

    $element.prop("disabled", toggle);
  }

  toggleHidden(element: any, toggle: any) {
    const $element = $(element);

    $element.toggleClass("hidden", toggle);
  }
}

export default Layout
